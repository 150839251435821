import { observer } from "mobx-react-lite";
import useRestricted from "../hooks/useRestricted";
import React, { ChangeEvent } from "react";
import { AppContext } from "../contexts/AppContext";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { round, getSlopesAndElevationSeriesFromGeojsonFeature, getElevationSeriesFromRaceSlopes, getElevation } from "../utils/UnitsUtils";
import { Point } from "../types/visualization";
import { PlotContainer } from "../components/common/PlotContainer";
import { Race, RaceGpxMetadata } from "../services/RacesService";
import dayjs from "dayjs";
import RacesAutocomplete from "../components/plans/RacesAutocomplete";
import { FirebaseError } from "firebase/app";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { gpx } from "@tmcw/togeojson";

type AddRaceSlope = [number, number, "up" | "down" | "flat"];

const AddRace: React.FC = observer(() => {

  const loadingUser = useRestricted("admin");
  const { userService, racesService } = React.useContext(AppContext);

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [distancePoints, setDistancePoints] = React.useState<number[]>([]);
  const [altitudePoints, setAltitudePoints] = React.useState<number[]>([]);
  const [elevationSeries, setElevationSeries] = React.useState<Point[]>([]);

  const [aidStationsText, setAidStationsText] = React.useState<string>("");
  const [aidStations, setAidStations] = React.useState<number[]>([]);
  const [aidStationNames, setAidStationNames] = React.useState<string[]>([]);

  const [gpxFile, setGpxFile] = React.useState<File | null>(null);
  const [gpxSlopesText, setGpxSlopesText] = React.useState<string>("");
  const [gpxSlopes, setGpxSlopes] = React.useState<AddRaceSlope[]>([]);

  const [racesText, setRacesText] = React.useState<string>("");
  const [raceIndex, setRaceIndex] = React.useState<number>(-1);

  const [currentRace, setCurrentRace] = React.useState<Partial<Race>>({ createdBy: userService.user?.id || "" });
  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  const [currentGpxMetadata, setCurrentGpxMetadata] = React.useState<Partial<RaceGpxMetadata>>({});

  if (loadingUser) {
    return null;
  }

  const handleSelectRace = (race: Race | null) => {
    if (race) {
      setIsEdit(true);
      setCurrentRace(race);
    } else {
      setIsEdit(false);
      setCurrentRace({});
    }
  }

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string, type: "string" | "number" = "string") => {
    setError(null);
    setCurrentRace({ ...currentRace, [key]: type === "number" ? +event.target.value : event.target.value });
  }

  const handleChangeDate = (date: dayjs.Dayjs | null) => {
    setError(null);
    setCurrentRace({ ...currentRace, date: date ? date.toDate() : undefined });
  }

  const handleChangeGpxDate = (date: dayjs.Dayjs | null) => {
    setError(null);
    setCurrentGpxMetadata({ ...currentGpxMetadata, date: date ? date.toDate() : undefined });
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files.length) {
      const file = event.target.files[0];
      parseGpx(file);
    }
  };

  const parseGpx = (file: File, raceValues?: Partial<Race>) => {
    setGpxFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const parser = new DOMParser();
      if (e?.target?.result) {
        const xmlDoc = parser.parseFromString(
          e.target.result as string,
          "text/xml"
        );
        const geoJson = gpx(xmlDoc);
        if (geoJson && geoJson.features && geoJson.features.length > 0) {
          const { elevationSeries, distancePoints, altitudePoints } = getSlopesAndElevationSeriesFromGeojsonFeature(geoJson.features[0]);
          setElevationSeries(elevationSeries);
          setDistancePoints(distancePoints);
          setAltitudePoints(altitudePoints);

          const elevationData = getElevation(altitudePoints);
          setCurrentRace({ ...currentRace,  ...raceValues, distance: round(distancePoints[distancePoints.length - 1] / 1000, 2), elevationGain: elevationData.elevationGain, elevationLoss: elevationData.elevationLoss });
        }
      }
    };
    reader.readAsText(file);
  }

  const handleChangeAidStations = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    try {
      setAidStationsText(event.target.value);
      const aidStations = event.target.value.split(",").map((value) => +value * 1000);
      setAidStations(aidStations);
    } catch (error) {
      console.error("Aid stations in wrong format");
    }
  }

  const handleChangeSlopes = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    try {
      setGpxSlopesText(event.target.value);
      const slopesLines = event.target.value.split("\n");
      const slopes = slopesLines.map((line) => {
        const values = line.split(",");
        return [+values[0], +values[1], values[2].trim() as "up" | "down" | "flat"] as AddRaceSlope;
      });
      setGpxSlopes(slopes);
      setElevationSeries(getElevationSeriesFromRaceSlopes(slopes.map(s => {
        return {
          startDistance: s[0] * 1000,
          endDistance: s[1] * 1000,
          type: s[2]
        }
      }
      ), distancePoints, altitudePoints));
    } catch (error) {
      console.error("Aid stations in wrong format");
    }
  }

  const handleChangeRaces = (event: ChangeEvent<HTMLInputElement>) => {
    setRacesText(event.target.value);
  }

  const createFile = async (path: string, name: string, type: string): Promise<File> => {
    let response = await fetch(path);
    let data = await response.blob();
    let metadata = {
      type: type
    };
    return new File([data], name, metadata);
  }

  const handleChangeRaceIndex = (index: number) => {
    setRaceIndex(index);
    const races = racesText.split("\n");
    const raceData = races[index].split("|");

    const aidStationsSplit = raceData[5].split(",");
    aidStationsSplit.pop();
    setAidStationsText(aidStationsSplit.join(","));
    const aidStations = aidStationsSplit.map((value: string) => +value * 1000);
    setAidStations(aidStations);
    setAidStationNames(raceData[7].split(","));

    handleChangeGpxDate(dayjs(raceData[3]));
    createFile("gpx/"+raceData[6], raceData[0] + ".gpx", "application/gpx+xml").then((file) => {
      parseGpx(file, {
        id: raceData[0],
        name: raceData[1],
        main: raceData[2],
        date: dayjs(raceData[3]).toDate(),
        website: raceData[4],
      });
    });
  }


  const handleAddRace = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    if (!currentRace.id || !currentRace.date || !currentRace.name) {
      setError("All fields are required");
      setLoading(false);
      return;
    }
    try {
      await racesService.upsertRace(currentRace as Race);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.code);
      } else {
        setError("An unknown error occurred");
      }
      setLoading(false);
      return;
    }
    racesService.loadRaces();
    setLoading(false);
    setIsEdit(true);
  };

  const handleAddGpx = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    if (!currentRace.id || !currentRace.date || !currentRace.name || !currentGpxMetadata.date || !gpxFile || !gpxSlopes.length || !aidStations.length) {
      setError("All fields are required");
      setLoading(false);
      return;
    }

    const newCurrentRace = {
      ...currentRace, gpxFiles: [...currentRace.gpxFiles || [], {
        ...currentGpxMetadata, aidStations: aidStations.map(aidStation => {
          return { distance: aidStation };
        }), slopes: gpxSlopes.map(slope => {
          return {
            startDistance: slope[0] * 1000,
            endDistance: slope[1] * 1000,
            type: slope[2]
          }
        }),
        aidStationNames
      }]
    };

    try {
      await racesService.upsertRace(newCurrentRace as Race);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.code);
      } else {
        setError("An unknown error occurred");
      }
      setLoading(false);
      return;
    }

    try {
      await racesService.addGpxFile(newCurrentRace.id!, currentGpxMetadata as RaceGpxMetadata, gpxFile);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.code);
      } else {
        setError("An unknown error occurred");
      }
      setLoading(false);
      return;
    }

    setCurrentRace(newCurrentRace as Race);
    setLoading(false);
    setIsEdit(true);
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >

      <TextField
        margin="normal"
        required
        fullWidth
        id="races"
        label="Races per line (id | name | main | date | website |  aidStations | gpxFile)"
        name="races"
        value={racesText}
        onChange={handleChangeRaces}
        multiline
      />
      <Button onClick={() => handleChangeRaceIndex(raceIndex + 1)}>Next</Button>

      <Typography component="h1" variant="h5">
        {isEdit ? "Edit" : "Add"} Race
      </Typography>
      <Box component="form" noValidate onSubmit={handleAddRace} sx={{ mt: 1 }}>
        <RacesAutocomplete onChooseRace={handleSelectRace} />
        <TextField
          margin="normal"
          required
          fullWidth
          id="id"
          label="Id"
          name="id"
          autoFocus
          value={currentRace.id || ""}
          onChange={(event) => handleChangeValue(event, "id")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={currentRace.name || ""}
          onChange={(event) => handleChangeValue(event, "name")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="main"
          label="Main Race Name"
          name="main"
          value={currentRace.main || ""}
          onChange={(event) => handleChangeValue(event, "main")}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Race Date"
            sx={{ mt: 2, mb: 1, width: "100%" }}
            name="date"
            value={currentRace.date ? dayjs(currentRace.date) : undefined}
            onChange={handleChangeDate}
          />
        </LocalizationProvider>
        <TextField
          margin="normal"
          required
          fullWidth
          id="website"
          label="Website"
          name="website"
          value={currentRace.website || ""}
          onChange={(event) => handleChangeValue(event, "website")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="distance"
          label="Distance"
          name="distance"
          value={currentRace.distance || ""}
          type="number"
          onChange={(event) => handleChangeValue(event, "distance", "number")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="elevationGain"
          label="Elevation Gain"
          name="elevationGain"
          type="number"
          value={currentRace.elevationGain || ""}
          onChange={(event) => handleChangeValue(event, "elevationGain", "number")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="elevationLoss"
          label="Elevation Loss"
          name="elevationLoss"
          type="number"
          value={currentRace.elevationLoss || ""}
          onChange={(event) => handleChangeValue(event, "elevationLoss", "number")}
        />
        {
          error && <Alert severity="error">{error}</Alert>
        }
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          disabled={!currentRace.id || !currentRace.date || !currentRace.name}
        >
          {isEdit ? "Edit" : "Add"} Race
        </LoadingButton>

      </Box>
      <Box component="form" noValidate onSubmit={handleAddGpx} sx={{ mt: 1 }}>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Gpx for Date"
            sx={{ mt: 2, mb: 1, width: "100%" }}
            name="gpxdate"
            value={currentGpxMetadata.date ? dayjs(currentGpxMetadata.date) : undefined}
            onChange={handleChangeGpxDate}
          />
        </LocalizationProvider>
        <input
          className="form-control"
          id="myGPX"
          type="file"
          accept=".gpx"
          onChange={handleFileChange}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="aidStations"
          label="Aid Stations (in km divided by comma)"
          name="aidStations"
          value={aidStationsText}
          onChange={handleChangeAidStations}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="slopes"
          label="Slopes (in km divided by comma and semi colon 'start, end, slope; ...')"
          name="slopes"
          value={gpxSlopesText}
          onChange={handleChangeSlopes}
          multiline
        />

        {!!elevationSeries && !!elevationSeries.length && <PlotContainer
          id="add-race-plot"
          data={elevationSeries}
          dimensions={{ width: 500, height: 300, margin: { top: 10, right: 10, bottom: 30, left: 40 } }}
          breaks={aidStations}
        />}

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          disabled={!elevationSeries.length || !isEdit}
        >
          Save Gpx
        </LoadingButton>

      </Box>
    </Box>
  );
});

export default AddRace;
