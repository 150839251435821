import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Help() {

    const intl = useIntl();

    return (
        <Container
            id="pricing"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary" marginBottom={2}>
                    {intl.formatMessage({ id: "help.title" })}
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-create"
                        id="panel-create"
                    >
                        <Typography component="span">1. {intl.formatMessage({ id: "help.createPlan" })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <video width="100%" controls src={`https://timeontrails.com/videos/create_plan_${intl.locale.toLowerCase()}.mp4`}>
                            Your browser does not support the video tag.
                        </video>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-visualization"
                        id="panel-visualization"
                    >
                        <Typography component="span">2. {intl.formatMessage({ id: "help.visualization" })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <video width="100%" controls src={`https://timeontrails.com/videos/visualization_${intl.locale.toLowerCase()}.mp4`}>
                            Your browser does not support the video tag.
                        </video>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-pacing"
                        id="panel-pacing"
                    >
                        <Typography component="span">3. {intl.formatMessage({ id: "help.pacing" })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <video width="100%" controls src={`https://timeontrails.com/videos/pace_${intl.locale.toLowerCase()}.mp4`}>
                            Your browser does not support the video tag.
                        </video>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-nutrition"
                        id="panel-nutrition"
                    >
                        <Typography component="span">4. {intl.formatMessage({ id: "help.nutrition" })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <video width="100%" controls src={`https://timeontrails.com/videos/nutrition_${intl.locale.toLowerCase()}.mp4`}>
                            Your browser does not support the video tag.
                        </video>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-notes"
                        id="panel-notes"
                    >
                        <Typography component="span">5. {intl.formatMessage({ id: "help.notes" })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <video width="100%" controls src={`https://timeontrails.com/videos/notes_${intl.locale.toLowerCase()}.mp4`}>
                            Your browser does not support the video tag.
                        </video>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-export"
                        id="panel-export"
                    >
                        <Typography component="span">6. {intl.formatMessage({ id: "help.export" })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <video width="100%" controls src={`https://timeontrails.com/videos/export_${intl.locale.toLowerCase()}.mp4`}>
                            Your browser does not support the video tag.
                        </video>
                    </AccordionDetails>
                </Accordion>
                <Typography variant="body1" color="text.secondary" marginTop={2}>
                    {intl.formatMessage({ id: "help.contact" })} <br />
                    <a href="mailto:support@timeontrails.com">support@timeontrails.com</a>
                </Typography>
            </Box>
        </Container>
    );
}