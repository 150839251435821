import { Slope } from "../types/slope";
import { Point } from "../types/visualization";
import { RaceGpxSlope } from "../services/RacesService";
import { ControlPoint, Section, SectionData } from "../services/PlansService";

// distance in km or miles, time in mins
export function getPace(distance: number, time: number) {
  const pace = (time / distance);
  const leftover = pace % 1;
  const paceMin = pace - leftover;
  const paceSec = Math.round(leftover * 60);

  return `${paceMin}:${paceSec < 10 ? `0${paceSec}` : paceSec}`;
}

export function round(value: number, precision: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function secondsPerMeterToMinutesPerKm(value: number) {
  return value > 0 ? (1 / value / 60) * 1000 : 0;
}

export function secondsToHHMMSS(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor(totalSeconds / 60) % 60
  const seconds = Math.round(totalSeconds % 60)

  const unit = ['h', 'min', 's'];
  return [hours, minutes, seconds]
    .map((v, i) => (v < 10 ? "0" + v : v) + unit[i])
    .filter((v, i) => v !== "00" || i > 0)
    .join(":")
}

function strTimeToSeconds(strTime: string): number {
  const completedStrTime = strTime.replaceAll("_", "0");
  return (+completedStrTime.substring(0, 2) * 60) + (+completedStrTime.substring(3, 5));
}

export function calculateTotalPlannedTime(slopes: Slope[], strTimes: (string | undefined | null)[]) {
  const totalPlannedTime = slopes.reduce((acc, slope, index) => {
    return acc + ((slope.endDistance - slope.startDistance) / 1000) * (strTimes[index] ? strTimeToSeconds(strTimes[index] as string) : 0);
  }, 0);
  return totalPlannedTime;
}

export function divideBySlope(
  distance: number[],
  altitude: number[],
  latlng: number[] | number[][],
  percentage: number,
): Slope[] {
  const fuzzConstant = Math.round(distance.length * 0.005);

  const minDistance = distance[distance.length - 1] * 0.01;

  const slopesFlat = [];
  const slopesUphill = [];
  const slopesDownhill = [];

  let currentSegmentStart = 0;
  let currentSegmentEnd = 0;
  for (let i = 0; i < distance.length; i++) {
    const distanceDiffFront =
      distance[Math.min(i + fuzzConstant, distance.length)] - distance[i];
    const distanceDiffBack =
      distance[Math.min(i + fuzzConstant, distance.length)] - distance[i];

    const slopeFront = distanceDiffFront
      ? ((altitude[Math.min(i + fuzzConstant, distance.length)] - altitude[i]) /
        distanceDiffFront) *
      100
      : 0;
    const slopeBack = distanceDiffBack
      ? ((altitude[i] - altitude[Math.max(i - fuzzConstant, 0)]) /
        (distance[i] - distance[Math.max(i - fuzzConstant, 0)])) *
      100
      : distanceDiffBack;

    if (
      (slopeFront > -100 && slopeFront < percentage * -1) ||
      (slopeBack > -100 && slopeBack < percentage * -1)
    ) {
      slopesDownhill.push(1);
      slopesFlat.push(0);
      slopesUphill.push(0);
    } else if (
      (slopeFront > percentage && slopeFront < 100) ||
      (slopeBack > percentage && slopeBack < 100)
    ) {
      slopesUphill.push(1);
      slopesFlat.push(0);
      slopesDownhill.push(0);
    } else {
      slopesFlat.push(1);
      slopesDownhill.push(0);
      slopesUphill.push(0);
    }
  }

  const segments = [];

  let currentSegmentType = -2;
  for (let i = 0; i < slopesFlat.length; i++) {
    const remainingFront = Math.min(i + fuzzConstant, slopesFlat.length) - i;
    const remainingBack = i - Math.max(i - fuzzConstant, 0);

    const subArrayFrontFlat = slopesFlat.slice(
      i,
      Math.min(i + fuzzConstant, slopesFlat.length)
    );
    const subArrayBackFlat = slopesFlat.slice(Math.max(i - fuzzConstant, 0), i);

    const subArrayFrontDownhill = slopesDownhill.slice(
      i,
      Math.min(i + fuzzConstant, slopesDownhill.length)
    );
    const subArrayBackDownhill = slopesDownhill.slice(Math.max(i - fuzzConstant, 0), i);


    const subArrayFrontUphill = slopesUphill.slice(
      i,
      Math.min(i + fuzzConstant, slopesUphill.length)
    );
    const subArrayBackUphill = slopesUphill.slice(Math.max(i - fuzzConstant, 0), i);

    if (
      [...subArrayFrontFlat, ...subArrayBackFlat].reduce((a, b) => a + b, 0) >=
      Math.floor((remainingFront + remainingBack) / 2)
    ) {

      currentSegmentEnd = i;

      if (currentSegmentType !== 0) {
        if (
          currentSegmentEnd > currentSegmentStart &&
          distance[currentSegmentEnd] - distance[currentSegmentStart] >
          minDistance
        ) {
          segments.push({
            startIndex: currentSegmentStart,
            endIndex: currentSegmentEnd,
            startDistance: distance[currentSegmentStart],
            endDistance: distance[currentSegmentEnd],
            startAltitude: altitude[currentSegmentStart],
            endAltitude: altitude[currentSegmentEnd],
            type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
            latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
          });
          currentSegmentStart = i;
        }
      }

      currentSegmentType = 0;
      if (
        i === slopesFlat.length - 1 &&
        currentSegmentEnd > currentSegmentStart &&
        distance[currentSegmentEnd] - distance[currentSegmentStart] >
        minDistance
      ) {
        segments.push({
          startIndex: currentSegmentStart,
          endIndex: currentSegmentEnd,
          startDistance: distance[currentSegmentStart],
          endDistance: distance[currentSegmentEnd],
          startAltitude: altitude[currentSegmentStart],
          endAltitude: altitude[currentSegmentEnd],
          type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
          latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
        });
        currentSegmentStart = i;
      }
    } else if (
      [...subArrayFrontDownhill, ...subArrayBackDownhill].reduce((a, b) => a + b, 0) >=
      Math.floor((remainingFront + remainingBack) / 2)
    ) {
      currentSegmentEnd = i;

      if (currentSegmentType !== -1) {
        if (
          currentSegmentEnd > currentSegmentStart &&
          distance[currentSegmentEnd] - distance[currentSegmentStart] >
          minDistance
        ) {
          segments.push({
            startIndex: currentSegmentStart,
            endIndex: currentSegmentEnd,
            startDistance: distance[currentSegmentStart],
            endDistance: distance[currentSegmentEnd],
            startAltitude: altitude[currentSegmentStart],
            endAltitude: altitude[currentSegmentEnd],
            type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
            latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
          });
          currentSegmentStart = i;
        }
      }

      currentSegmentType = -1;
      if (
        i === slopesDownhill.length - 1 &&
        currentSegmentEnd > currentSegmentStart &&
        distance[currentSegmentEnd] - distance[currentSegmentStart] >
        minDistance
      ) {
        segments.push({
          startIndex: currentSegmentStart,
          endIndex: currentSegmentEnd,
          startDistance: distance[currentSegmentStart],
          endDistance: distance[currentSegmentEnd],
          startAltitude: altitude[currentSegmentStart],
          endAltitude: altitude[currentSegmentEnd],
          type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
          latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
        });
        currentSegmentStart = i;
      }
    } else if (
      [...subArrayFrontUphill, ...subArrayBackUphill].reduce((a, b) => a + b, 0) >=
      Math.floor((remainingFront + remainingBack) / 2)
    ) {
      currentSegmentEnd = i;

      if (currentSegmentType !== 1) {
        if (
          currentSegmentEnd > currentSegmentStart &&
          distance[currentSegmentEnd] - distance[currentSegmentStart] >
          minDistance
        ) {
          segments.push({
            startIndex: currentSegmentStart,
            endIndex: currentSegmentEnd,
            startDistance: distance[currentSegmentStart],
            endDistance: distance[currentSegmentEnd],
            startAltitude: altitude[currentSegmentStart],
            endAltitude: altitude[currentSegmentEnd],
            type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
            latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
          });
          currentSegmentStart = i;
        }

      }

      currentSegmentType = 1;
      if (
        i === slopesUphill.length - 1 &&
        currentSegmentEnd > currentSegmentStart &&
        distance[currentSegmentEnd] - distance[currentSegmentStart] >
        minDistance
      ) {
        segments.push({
          startIndex: currentSegmentStart,
          endIndex: currentSegmentEnd,
          startDistance: distance[currentSegmentStart],
          endDistance: distance[currentSegmentEnd],
          startAltitude: altitude[currentSegmentStart],
          endAltitude: altitude[currentSegmentEnd],
          type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
          latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
        });
        currentSegmentStart = i;
      }
    } else {
      if (
        i === slopesFlat.length - 1 &&
        currentSegmentEnd > currentSegmentStart
      ) {
        segments.push({
          startIndex: currentSegmentStart,
          endIndex: currentSegmentEnd,
          startDistance: distance[currentSegmentStart],
          endDistance: distance[currentSegmentEnd],
          startAltitude: altitude[currentSegmentStart],
          endAltitude: altitude[currentSegmentEnd],
          type: getSegmentTypeByAverageSlope(distance.slice(currentSegmentStart, currentSegmentEnd), altitude.slice(currentSegmentStart, currentSegmentEnd), percentage),
          latlngData: latlng.slice(currentSegmentStart, currentSegmentEnd)
        });
        currentSegmentStart = i;
      }
    }
  }

  segments[0].startIndex = 0;
  segments[0].startDistance = distance[0];
  segments[0].startAltitude = altitude[0];
  segments[0].latlngData = latlng.slice(0, segments[0].endIndex);

  segments[segments.length - 1].endIndex = distance.length - 1;
  segments[segments.length - 1].endDistance = distance[distance.length - 1];
  segments[segments.length - 1].endAltitude = altitude[altitude.length - 1];
  segments[segments.length - 1].latlngData = latlng.slice(segments[segments.length - 1].startIndex, segments[segments.length - 1].endIndex);

  return smoothSegmentEdges(mergeSegments(segments, minDistance), distance, altitude, latlng);
}

function smoothSegmentEdges(segments: Slope[], distance: number[], altitude: number[], latlng: number[] | number[][]) {

  for (let i = segments.length - 1; i > 0; i--) {
    const segment = segments[i];

    let currentNewIndexPrev = -1;
    let currentPrevAltitude = segment.startAltitude;
    const prevSegment = segments[i - 1];
    const prevSegmentAltitudes = altitude.slice(prevSegment.startIndex, prevSegment.endIndex);

    for (let j = prevSegmentAltitudes.length - 1; j >= 0; j--) {

      if (segment.type === 1) {
        if (prevSegmentAltitudes[j] < currentPrevAltitude && (currentNewIndexPrev === -1 || prevSegmentAltitudes[j] < prevSegmentAltitudes[currentNewIndexPrev])) {
          currentNewIndexPrev = j;
        }
      }

      if (segment.type === -1) {
        if (prevSegmentAltitudes[j] > currentPrevAltitude && (currentNewIndexPrev === -1 || prevSegmentAltitudes[j] > prevSegmentAltitudes[currentNewIndexPrev])) {
          currentNewIndexPrev = j;
        }
      }
    }

    if (currentNewIndexPrev > -1) {
      const newPercentagePrev = ((prevSegmentAltitudes.length - currentNewIndexPrev) / prevSegmentAltitudes.length);
      //if (newPercentagePrev < 0.2) {
      prevSegment.endIndex = prevSegment.startIndex + currentNewIndexPrev;
      prevSegment.endDistance = distance[prevSegment.startIndex + currentNewIndexPrev];
      prevSegment.endAltitude = altitude[prevSegment.startIndex + currentNewIndexPrev];
      prevSegment.latlngData = latlng.slice(prevSegment.startIndex, prevSegment.startIndex + currentNewIndexPrev);
      segment.startIndex = prevSegment.endIndex + 1;
      //}
    }

    segment.startDistance = distance[segment.startIndex];
    segment.endDistance = distance[segment.endIndex];
    segment.startAltitude = altitude[segment.startIndex];
    segment.endAltitude = altitude[segment.endIndex];
    segment.latlngData = latlng.slice(segment.startIndex, segment.endIndex);
  }

  for (let i = 0; i < segments.length - 1; i++) {
    const segment = segments[i];

    let currentNewIndexNext = -1;
    let currentNextAltitude = segment.endAltitude;

    const nextSegment = segments[i + 1];

    const nextSegmentAltitudes = altitude.slice(nextSegment.startIndex, nextSegment.endIndex);

    for (let j = 0; j < nextSegmentAltitudes.length; j++) {

      if (segment.type === 1) {
        if (nextSegmentAltitudes[j] > currentNextAltitude && (currentNewIndexNext === -1 || nextSegmentAltitudes[j] > nextSegmentAltitudes[currentNewIndexNext])) {
          currentNewIndexNext = j;
        }
      }

      if (segment.type === -1) {
        if (nextSegmentAltitudes[j] < currentNextAltitude && (currentNewIndexNext === -1 || nextSegmentAltitudes[j] < nextSegmentAltitudes[currentNewIndexNext])) {
          currentNewIndexNext = j;
        }
      }
    }

    if (currentNewIndexNext > -1) {
      const newPercentageNext = (currentNewIndexNext / nextSegmentAltitudes.length);
      //if (newPercentageNext < 0.2) {
      nextSegment.startIndex = nextSegment.startIndex + currentNewIndexNext;
      nextSegment.startDistance = distance[nextSegment.startIndex];
      nextSegment.startAltitude = altitude[nextSegment.startIndex];
      nextSegment.latlngData = latlng.slice(nextSegment.startIndex, nextSegment.endIndex);
      segment.endIndex = nextSegment.startIndex - 1;
      //}
    }

    segment.startDistance = distance[segment.startIndex];
    segment.endDistance = distance[segment.endIndex];
    segment.startAltitude = altitude[segment.startIndex];
    segment.endAltitude = altitude[segment.endIndex];
    segment.latlngData = latlng.slice(segment.startIndex, segment.endIndex);
  }

  return segments;
}

function mergeSegments(segments: Slope[], minDistance: number): Slope[] {
  const newSegments: Slope[] = [];

  for (let index = 0; index < segments.length; index++) {
    const segment = segments[index];
    let prevSegment = index > 0 ? newSegments[newSegments.length - 1] : null;
    let merged = false;

    if (prevSegment && segment.type === prevSegment.type
    ) {
      const newSegment = {
        startIndex: prevSegment.startIndex,
        endIndex: segment.endIndex,
        startDistance: prevSegment.startDistance,
        endDistance: segment.endDistance,
        startAltitude: prevSegment.startAltitude,
        endAltitude: segment.endAltitude,
        type: segment.type,
        latlngData: ([...prevSegment.latlngData, ...segment.latlngData] as (number[] | number[][]))
      };
      newSegments[newSegments.length - 1] = newSegment;
      prevSegment = newSegment;
      merged = true;
    }

    if ((segment.endDistance - segment.startDistance) < minDistance * (segment.type === 0 ? 2 : 1) && prevSegment) {
      const newSegment = {
        startIndex: prevSegment.startIndex,
        endIndex: segment.endIndex,
        startDistance: prevSegment.startDistance,
        endDistance: segment.endDistance,
        startAltitude: prevSegment.startAltitude,
        endAltitude: segment.endAltitude,
        type: prevSegment.type,
        latlngData: ([...prevSegment.latlngData, ...segment.latlngData] as (number[] | number[][]))
      };
      newSegments[newSegments.length - 1] = newSegment;
      prevSegment = newSegment;
      merged = true;
    }

    if (prevSegment && (prevSegment.endDistance - prevSegment.startDistance) < minDistance * (prevSegment.type === 0 ? 2 : 1) && prevSegment.type === 0) {
      const newSegment = {
        startIndex: prevSegment.startIndex,
        endIndex: segment.endIndex,
        startDistance: prevSegment.startDistance,
        endDistance: segment.endDistance,
        startAltitude: prevSegment.startAltitude,
        endAltitude: segment.endAltitude,
        type: segment.type,
        latlngData: ([...prevSegment.latlngData, ...segment.latlngData] as (number[] | number[][]))
      };
      newSegments[newSegments.length - 1] = newSegment;
      prevSegment = newSegment;
      merged = true;
    }

    if (!merged) {
      newSegments.push(segment);
    }
  }

  return newSegments;
}

export function isOnSegments(index: number, segments: Slope[]) {
  for (let i = 0; i < segments.length; i++) {
    if (index >= segments[i].startIndex && index <= segments[i].endIndex) {
      return true;
    }
  }
  return false;
}

export function getTypeOnSegments(index: number, segments: Slope[]) {
  for (let i = 0; i < segments.length; i++) {
    if (index >= segments[i].startIndex && index <= segments[i].endIndex) {
      return segments[i].type;
    }
  }
  return null;
}

export function getSteepType(slope: number) {
  if (Math.abs(slope) < 3) return 1
  else if (Math.abs(slope) < 7 && Math.abs(slope) >= 3) return 2
  else if (Math.abs(slope) < 16 && Math.abs(slope) >= 7) return 3
  else if (Math.abs(slope) < 25 && Math.abs(slope) >= 16) return 4
  else return 5
}

export function getSmoothSlope(i: number, slopes: number[]) {
  const fuzzConstant = Math.round(slopes.length * 0.005);

  const subArrayFront = slopes.slice(
    i,
    Math.min(i + fuzzConstant, slopes.length)
  )

  const subArrayBack = slopes.slice(Math.max(i - fuzzConstant, 0), i);

  const slopesArray = [...subArrayFront, ...subArrayBack];

  const sum = slopesArray.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const avg = sum / slopesArray.length;

  return Math.round(avg);
}

export function getSegmentTypeByAverageSlope(distance: number[], altitude: number[], percentage: number) {


  const averageSlope = (altitude[altitude.length - 1] - altitude[0]) / (distance[altitude.length - 1] - distance[0]);

  if (averageSlope * 100 > percentage) {
    return 1;
  } else if (averageSlope * 100 < -1 * percentage) {
    return -1
  } else {
    return 0
  }

}

const deg2rad = (deg: number) => {
  return (deg * Math.PI) / 180;
};

export function getDistanceFromLatLonInKM(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6369087; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export function getSlopesAndElevationSeriesFromGeojsonFeature(feature: any) {

  if (feature.geometry && feature.geometry.coordinates) {
    const latlng: number[][] = [];
    const altitude: number[] = [];
    const distance: number[] = [0];
    let total_elevation_gain = 0;

    let lastCord: [number, number, number];
    feature.geometry.coordinates.forEach(

      (coordinate: number[], index: number) => {

        if (index > 0) {
          const elevationDifference =
            coordinate[2] -
            lastCord[2];
          if (elevationDifference > 0) {
            total_elevation_gain += elevationDifference;
          }
          const distanceBetweenPoints = getDistanceFromLatLonInKM(
            lastCord[1],
            lastCord[0],
            coordinate[1],
            coordinate[0]
          );
          if (distanceBetweenPoints > 0) {
            distance.push(distance[distance.length - 1] + distanceBetweenPoints);
            latlng.push([coordinate[0], coordinate[1]]);
            altitude.push(coordinate[2]);
            lastCord = [coordinate[0], coordinate[1], coordinate[2]];
          }
        } else {
          latlng.push([coordinate[0], coordinate[1]]);
          altitude.push(coordinate[2]);
          lastCord = [coordinate[0], coordinate[1], coordinate[2]];
        }
      }
    );

    const elevationSeriesTemp: Point[] = [];

    const slopes = distance.map((value: number, index: number) => {
      const startAltitude = index === 0 ? altitude[0] : altitude[index - 1];
      const endAltitude = index === altitude.length - 1 ? altitude[altitude.length - 1] : altitude[index + 1];

      const startDistance = index === 0 ? distance[0] : distance[index - 1];
      const endDistance = index === distance.length - 1 ? distance[distance.length - 1] : distance[index + 1];


      const slope = round(
        ((endAltitude - startAltitude) /
          (endDistance - startDistance > 0 ? endDistance - startDistance : 1)) *
        100,
        0
      );


      return slope;
    });

    distance.forEach((value: number, index: number) => {
      if (altitude) {

        elevationSeriesTemp.push({
          distance: distance[index],
          slope: slopes[index],
          elevation: altitude[index]
        });
      }
    });

    return {
      elevationSeries: elevationSeriesTemp,
      distancePoints: distance,
      altitudePoints: altitude,
      latlng: feature.geometry.coordinates,
    }
  }
  return {
    elevationSeries: [],
    distancePoints: [],
    altitudePoints: [],
    latlng: []
  }
}

export function getElevationSeriesFromRaceSlopes(slopeSegments: RaceGpxSlope[], distance: number[], altitude: number[]) {
  const elevationSeriesTemp: Point[] = [];
  const steepTypes: number[] = [];

  const slopes = distance.map((value: number, index: number) => {
    const startAltitude = index === 0 ? altitude[0] : altitude[index - 1];
    const endAltitude = index === altitude.length - 1 ? altitude[altitude.length - 1] : altitude[index + 1];

    const startDistance = index === 0 ? distance[0] : distance[index - 1];
    const endDistance = index === distance.length - 1 ? distance[distance.length - 1] : distance[index + 1];


    const slope = round(
      ((endAltitude - startAltitude) /
        (endDistance - startDistance > 0 ? endDistance - startDistance : 1)) *
      100,
      0
    );

    return slope;
  });


  distance.forEach((value: number, index: number) => {

    elevationSeriesTemp.push({
      distance: distance[index],
      slope: slopes[index],
      elevation: altitude[index]
    });
  });

  return elevationSeriesTemp;

}

export function getSectionsData(sections: Section[], controlPoints: ControlPoint[], distance: number[], altitude: number[]) {
  const sectionsData: SectionData[] = [];

  sections.forEach((section, sI) => {

    let minElevation = 9000;
    let maxElevation = 0;

    let prevElevation = -1;
    let prevElevationB = -1;
    let prevElevationA = -1;

    const elevations = distance.reduce((acc, distance, index) => {
      if (distance > section.startDistance && distance < section.endDistance && index > 0) {
        if (prevElevation === -1) {
          prevElevation = altitude[index];
        } else {
          const elevationGainDiff = Math.abs(altitude[index] - prevElevation);
          if (elevationGainDiff > 10) {
            if (altitude[index] - prevElevation > 0) {
              acc.elevationGain += elevationGainDiff;
            } else {
              acc.elevationLoss -= elevationGainDiff;
            }
            prevElevation = altitude[index];
          }
        }

        if (altitude[index] < minElevation) {
          minElevation = altitude[index];
        }

        if (altitude[index] > maxElevation) {
          maxElevation = altitude[index];
        }
      }

      if (distance <= section.startDistance && index > 0) {
        if (prevElevationB === -1) {
          prevElevationB = altitude[index];
        } else {
          const elevationGainDiff = Math.abs(altitude[index] - prevElevationB);
          if (elevationGainDiff > 10) {
            if (altitude[index] - prevElevationB > 0) {
              acc.beforeElevationGain += elevationGainDiff;
            } else {
              acc.beforeElevationLoss -= elevationGainDiff;
            }
            prevElevationB = altitude[index];
          }
        }
      }

      if (distance >= section.endDistance && index > 0) {
        if (prevElevationA === -1) {
          prevElevationA = altitude[index];
        } else {
          const elevationGainDiff = Math.abs(altitude[index] - prevElevationA);
          if (elevationGainDiff > 10) {
            if (altitude[index] - prevElevationA > 0) {
              acc.afterElevationGain += elevationGainDiff;
            } else {
              acc.afterElevationLoss -= elevationGainDiff;
            }
            prevElevationA = altitude[index];
          }
        }
      }

      return acc;
    }, { elevationGain: 0, elevationLoss: 0, beforeDistance: section.startDistance, beforeElevationGain: 0, beforeElevationLoss: 0, afterDistance: sections[sections.length - 1].endDistance - section.endDistance, afterElevationGain: 0, afterElevationLoss: 0 })

    const sectionData: SectionData = {
      startDistance: section.startDistance,
      endDistance: section.endDistance,
      distance: section.endDistance - section.startDistance,
      elevationGain: elevations.elevationGain,
      elevationLoss: elevations.elevationLoss,
      minElevation: minElevation,
      maxElevation: maxElevation,
      beforeDistance: elevations.beforeDistance,
      beforeElevationGain: elevations.beforeElevationGain,
      beforeElevationLoss: elevations.beforeElevationLoss,
      afterDistance: elevations.afterDistance,
      afterElevationGain: elevations.afterElevationGain,
      afterElevationLoss: elevations.afterElevationLoss,
      type: "section"
    };
    sectionsData.push(sectionData);
  });

  const completeSectionsData: SectionData[] = [];
  controlPoints.forEach((controlPoint, cI) => {

    const elevation = getElevationForDistance(controlPoint.distance * 1000, altitude, distance);
    const controlPointData: SectionData = {
      startDistance: controlPoint.distance * 1000,
      endDistance: controlPoint.distance * 1000,
      minElevation: elevation,
      maxElevation: elevation,
      beforeDistance: controlPoint.distance * 1000,
      beforeElevationGain: cI < sectionsData.length - 1 ? sectionsData[cI].beforeElevationGain : 0,
      beforeElevationLoss: cI < sectionsData.length - 1 ? sectionsData[cI].beforeElevationLoss : 0,
      afterDistance: cI < sectionsData.length - 1 ? sectionsData[sectionsData.length - 1].endDistance - controlPoint.distance * 1000 : 0,
      afterElevationGain: cI > 0 ? sectionsData[cI - 1].afterElevationGain : sectionsData[0].afterElevationGain + sectionsData[1].beforeElevationGain,
      afterElevationLoss: cI > 0 ? sectionsData[cI - 1].afterElevationLoss : sectionsData[0].afterElevationLoss + sectionsData[1].beforeElevationLoss,
      ...controlPoint,
    };

    completeSectionsData.push(controlPointData);
    if (cI < sectionsData.length)
      completeSectionsData.push(sectionsData[cI]);
  });

  return completeSectionsData;
}

export function getElevation(altitude: number[]) {

  let minElevation = 9000;
  let maxElevation = 0;

  let prevElevation = altitude[0];

  const elevations = altitude.reduce((acc, currentAltitude, index) => {
    const elevationGainDiff = Math.abs(currentAltitude - prevElevation);
    if (elevationGainDiff > 10) {
      if (currentAltitude - prevElevation > 0) {
        acc.elevationGain += elevationGainDiff;
      } else {
        acc.elevationLoss -= elevationGainDiff;
      }
      prevElevation = currentAltitude;
    }

    if (currentAltitude < minElevation) {
      minElevation = currentAltitude;
    }

    if (currentAltitude > maxElevation) {
      maxElevation = currentAltitude;
    }

    return acc;
  }, { elevationGain: 0, elevationLoss: 0 })

  const elevationData = {
    elevationGain: elevations.elevationGain,
    elevationLoss: elevations.elevationLoss,
    minElevation: minElevation,
    maxElevation: maxElevation,
  };

  return elevationData;
}

export const getLatLngForDistance = (distance: number, latlng: number[][], distancePoints: number[]) => {
  const index = distancePoints.findIndex((value) => value >= distance);
  if (index >= 0) {
    return latlng[index];
  }
  return [0, 0];
}

export const getElevationForDistance = (distance: number, elevations: number[], distancePoints: number[]) => {
  const index = distancePoints.findIndex((value) => value >= distance);
  if (index >= 0) {
    return elevations[index];
  }
  return 0;
}

export const convertDistance = (distanceInKm: number, unit: string) => {
  if (unit === "km") {
    return distanceInKm;
  }
  return round(distanceInKm * 0.621371, 1);
}

export const convertDistanceMiles = (distanceInMiles: number, unit: string) => {
  if (unit === "miles") {
    return distanceInMiles;
  }
  return round(distanceInMiles * 1.60934, 1);
}

export const convertElevation = (altitudeInM: number, unit: string) => {
  if (unit === "m") {
    return altitudeInM;
  }
  return round(altitudeInM * 3.28084, 0);
}
