import { makeAutoObservable } from "mobx";
import FirebaseService from "./FirebaseService";
import { DocumentData, QueryDocumentSnapshot, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, setDoc, startAfter, startAt, where } from "firebase/firestore";
import { getBlob, ref, uploadBytes } from "firebase/storage";
import RacesService, { RaceGpxMetadata } from "./RacesService";
import { PolylineLayerOptions } from "@maptiler/sdk";
import { Slope } from "../types/slope";
import { Point } from "../types/visualization";
import { getSlopesAndElevationSeriesFromGeojsonFeature, getSectionsData } from "../utils/UnitsUtils";
import { getBoundsOfGeojson } from "../utils/getBoundsOfGeojson";
import { DebouncedFunc, debounce } from "lodash";
import { gpx } from "@tmcw/togeojson";

export type Section = {
    startDistance: number;
    endDistance: number;
};

export type ControlPoint = {
    id?: string;
    name?: string;
    type: "start" | "end" | "control";
    distance: number;
    wc?: boolean;
    food?: boolean;
    drinks?: boolean;
    crew?: boolean;
    dropbag?: boolean;
    cutOff?: Date;
};

export type SectionData = Omit<ControlPoint, 'type'> & {
    type: "start" | "end" | "control" | "section";
    startDistance: number;
    endDistance: number;
    distance: number;
    elevationGain?: number;
    elevationLoss?: number;
    minElevation: number;
    maxElevation: number;
    beforeDistance: number;
    beforeElevationGain: number;
    beforeElevationLoss: number;
    afterDistance: number;
    afterElevationGain: number;
    afterElevationLoss: number;
};

export type PlannedEffort = {
    time: number;
    rpe: number;
};

export type NutritionProductPlan = {
    productId: string;
    quantity: number;
};

export type PlannedNutrition = {
    products: NutritionProductPlan[];
};

export type Plan = {
    id: string,
    name: string;
    date: Date;
    gpxFile: RaceGpxMetadata;
    createdAt: Date;
    modifiedAt: Date;
    createdBy: string;
    distance: number;
    elevationGain: number;
    elevationLoss: number;
    sections: Section[];
    controlPoints: ControlPoint[];
    plannedEffort: PlannedEffort[];
    plannedNutrition: PlannedNutrition[];
    notes: string[];
    type: "race" | "own",
    userId: string;
    raceId?: string;
    svg?: string;
};

export default class PlansService {

    get plans() {
        return this._plans;
    }

    get hasMorePlans() {
        return this._hasMorePlans;
    }

    get isLoadingPlans() {
        return this._isLoadingPlans;
    }

    get isSavingPlan() {
        return this._isSavingPlan;
    }

    get hasSaveError() {
        return this._hasSaveError;
    }

    get plan() {
        return this._plan;
    }

    get elevationSeries() {
        return this._elevationSeries;
    }

    get distancePoints() {
        return this._distancePoints;
    }

    get sectionsData() {
        return this._sectionsData;
    }

    get slopes() {
        return this._slopes;
    }

    get currentSection() {
        return this._currentSection;
    }

    get polylinesSteep() {
        return this._polylinesSteep;
    }

    get polylinesSlope() {
        return this._polylinesSlope;
    }

    get polylinesFull() {
        return this._polylinesFull;
    }

    get bbox() {
        return this._bbox;
    }

    get latlng() {
        return this._latlng;
    }

    private _firebaseService: FirebaseService;
    private _racesService: RacesService;

    private _plans: Plan[] = [];
    private _currentLatestPlan: QueryDocumentSnapshot<DocumentData, DocumentData> | null = null;
    private _hasMorePlans = true;

    private _isLoadingPlans = false;
    private _isSavingPlan = false;
    private _hasSaveError = false;

    private _plan: Plan | null = null;
    private _elevationSeries: Point[] = [];
    private _distancePoints: number[] = [];
    private _sectionsData: SectionData[] = [];
    private _slopes: Slope[] = [];
    private _currentSection: number = 0;
    private _polylinesSteep: PolylineLayerOptions[] = [];
    private _polylinesSlope: PolylineLayerOptions[] = [];
    private _polylinesFull: PolylineLayerOptions[] = [];
    private _bbox: [number, number, number, number] | undefined;
    private _latlng: [number, number][] = [];

    private readonly saveDelayHandler: DebouncedFunc<() => void>;

    constructor(firebaseService: FirebaseService, racesService: RacesService) {

        this._firebaseService = firebaseService;
        this._racesService = racesService;

        this.saveDelayHandler = debounce(() => {
            if (this._plan) {
                this._isSavingPlan = true;
                this._hasSaveError = false;
                this.upsertPlan(this._plan.userId, this._plan).then((e) => {
                    this._isSavingPlan = false;
                }).catch((e) => {
                    this._isSavingPlan = false;
                    this._hasSaveError = true;
                    console.error(e);
                });

            }
        }, 1000, { leading: false, trailing: true });

        makeAutoObservable(this, undefined, { autoBind: true, deep: true })
    }

    async loadPlan(userId: string, planId: string): Promise<Plan | null> {

        const db = this._firebaseService.db;
        const docRef = doc(db, `plans/${userId}-${planId}`);
        return getDoc(docRef).then((docSnapshot) => {
            if (!docSnapshot.exists()) {
                return null;
            }
            const docSnapshotData = docSnapshot.data();
            const gpxMetadata = docSnapshotData.gpxFile && docSnapshotData.gpxFile.date ? { ...docSnapshotData.gpxFile, date: docSnapshotData.gpxFile.date.toDate() } : undefined;
            this._plan = {
                ...docSnapshotData,
                date: docSnapshotData.date.toDate(),
                gpxFile: gpxMetadata,
                plannedNutrition: docSnapshotData.plannedNutrition ? JSON.parse(docSnapshotData.plannedNutrition) : [],
                controlPoints: (docSnapshotData.controlPoints || []).map((cp: any) => ({ ...cp, cutOff: cp.cutOff ? cp.cutOff.toDate() : 0 }))
            } as Plan;
            return this._plan;
        }).catch(() => {
            return null;
        });
    };

    async loadPlans(userId: string, fromStart: boolean = false) {
        if (this._isLoadingPlans) {
            return;
        }

        if (fromStart) {
            this._currentLatestPlan = null;
            this._hasMorePlans = true;
            this._plans = [];
        }

        this._isLoadingPlans = true;
        const db = this._firebaseService.db;

        const collectionRef = collection(db, `plans`);
        const q = this._currentLatestPlan ? query(collectionRef, orderBy("date", "desc"), where('userId', '==', userId), limit(10), startAfter(this._currentLatestPlan)) :
            query(collectionRef, orderBy("date", "desc"), where('userId', '==', userId), limit(10));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length < 10) {
            this._hasMorePlans = false;
        }
        if (querySnapshot.docs.length > 0) {
            this._currentLatestPlan = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
        this._plans = [...this.plans, ...querySnapshot.docs.map(
            (docSnapshot) => {
                const planData = docSnapshot.data();
                return {
                    ...planData,
                    date: planData.date.toDate(),
                    plannedNutrition: planData.plannedNutrition ? JSON.parse(planData.plannedNutrition) : [],
                    controlPoints: (planData.controlPoints || []).map((cp: any) => ({ ...cp, cutOff: cp.cutOff ? cp.cutOff.toDate() : 0 }))
                } as Plan
            })];
        this._isLoadingPlans = false;
    }

    async upsertPlan(userId: string, plan: Plan) {
        const db = this._firebaseService.db;
        const documentRef = doc(db, `plans/${userId}-${plan.id}`);

        console.log(plan);
        const planToSave = { ...plan, plannedNutrition: JSON.stringify(plan.plannedNutrition || []) };
        return setDoc(documentRef, planToSave);
    }

    async deletePlan(userId: string, planId: string) {
        const db = this._firebaseService.db;
        const documentRef = doc(db, `plans/${userId}-${planId}`);

        return deleteDoc(documentRef);
    }

    async addGpxFile(bucket: string, file: File, name: string) {

        const storage = this._firebaseService.storage;
        const storageRef = ref(storage, `${bucket}/${name}.gpx`);

        return uploadBytes(storageRef, file);
    }

    async loadGpxFile(bucket: string, userId: string, planId: string) {

        const storage = this._firebaseService.storage;
        const storageRef = ref(storage, `${bucket}/${userId}-${planId}.gpx`);

        return getBlob(storageRef).then((gpxFile) => {
            return new File([gpxFile], `${userId}-${planId}`);
        }).catch(() => {
            return null;
        });
    }

    async parseCurrentPlanGpxFile() {
        if (!this._plan) {
            return;
        }

        if (this._plan.raceId && this._plan.gpxFile) {
            return this._racesService.getGpxFile(this._plan.raceId, this._plan.gpxFile).then((gpxFile) => {
                if (gpxFile) {
                    this.handleFile(gpxFile);
                    return true;
                }
                return false;
            });
        } else {
            return this.loadGpxFile(`usersFiles/${this._plan.userId}`, this._plan.userId, this._plan.id).then((gpxFile) => {
                if (gpxFile) {
                    this.handleFile(gpxFile);
                    return true;
                }
                return false;
            });
        }
    }

    setPlanData(plan: Plan, dontSave: boolean = false) {
        this._plan = plan;

        if (!dontSave) {
            this.saveDelayHandler();
        }
    }

    clearPlan() {
        this._plan = null;
        this._elevationSeries = [];
        this._distancePoints = [];
        this._slopes = [];
        this._currentSection = 0;
        this._polylinesSteep = [];
        this._polylinesSlope = [];
        this._polylinesFull = [];
        this._bbox = undefined;
        this._latlng = [];
    }

    private handleFile(file: File) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const parser = new DOMParser();
            if (e?.target?.result) {
                const xmlDoc = parser.parseFromString(
                    e.target.result as string,
                    "text/xml"
                );
                const geoJson = gpx(xmlDoc);
                if (geoJson && geoJson.features && geoJson.features.length > 0) {
                    const { elevationSeries, distancePoints, altitudePoints, latlng } = getSlopesAndElevationSeriesFromGeojsonFeature(geoJson.features[0]);
                    
                    this._elevationSeries = elevationSeries;
                    this._sectionsData = getSectionsData(this._plan?.sections || [], this._plan?.controlPoints || [], distancePoints, altitudePoints);

                    this._distancePoints = distancePoints;
                    this._bbox = getBoundsOfGeojson(geoJson);

                    this._latlng = latlng;
                }
            }
        };
        reader.readAsText(file);
    }
}